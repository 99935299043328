<template>
  <div id="area_tabela">
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Título</th>
          <th>Autor</th>
          <th>Status</th>
          <th>Prazo</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(demanda) in demandas" 
          :key="demanda._id"
          v-show="filtro(demanda)"
        >
          <td class="acao">
            <div class="svg_acao" title="Abrir solicitação">
            <router-link :to="{ name: 'Demanda', params: { id: demanda._id }}">
              <icone-link />
            </router-link>
          </div>
          </td>

          <td>{{demanda.titulo}}</td>
          <td>{{demanda.autor.nome}}</td>
          <td>
            <icones-demanda :icone="demanda.status" />
          </td>
          <td>
            <p
              :style="{ color: corStatus(demanda.prazo, demanda.status)}"
            >
              {{stringifyDate(demanda.prazo)}}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import datesMixins from '@/mixins/dates'
import filterDemanda from '@/mixins/filterDemanda'

import IconeLink from '@/assets/SVGs/iconeLink.vue'

import IconesDemandas from '@/components/Icones/Status/Demandas.vue'

export default {
  components: {
    'icone-link': IconeLink,
    'icones-demanda': IconesDemandas,
  },
  props: {
    demandas: {
      required: true,
      type: Array
    }
  },
  mixins: [datesMixins, filterDemanda],
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import 'Demandas.scss';
@import '../Tables.scss';
</style>