<template>
	<div @dblclick="dblClickHandler" class="modal-overlay active">
		<div @click.stop="" class="modal">
      <span @click="toggleModal" class="close" title="Fechar campo">
        <icone-fechar />
      </span>
						
			<div id="form">
				<h2 id="add_title">Abrir Demanda</h2>

				<form @submit.prevent="validate">
          <div class="input-group">
            <h3 class="sr-only" for="titulo">Título</h3>
            <input required autocomplete="off" 
              type="text" id="add_titulo" name="titulo" placeholder="Título da demanda"
              v-model="modalData.titulo"
            >
          </div>

          <div class="input-group">
            <h3 class="sr-only" for="descricao">Descrição</h3>
            <textarea required autocomplete="off" 
              id="add_descricao" name="descricao" placeholder="Descrição da demanda"
              v-model="modalData.descricao"
            >
            </textarea>
          </div>

          <div class="input-group">
            <h3 class="sr-only" for="descricao">Responsáveis</h3>
            <seletor-usuarios 
              @seletorChange="selectResponsaveis"
            />
          </div>

          <div class="input-group">
            <h3 class="sr-only" for="prazo">Prazo</h3>
            <input required autocomplete="off" 
              type="datetime-local" id="add_prazo" name="prazo"
              :min="dataHoje"
              :max="dataProximoAno"
              v-model="modalData.prazo"
            >
          </div>

          <div class="input-group">
            <h3 class="sr-only">Anexos</h3>
            <input type="file" name="anexo_1" id="anexo_1"
              accept="image/*, .pdf, .docx, .doc, .xlsx, .ods, .zip, .ppt, .pptx, .txt"
            >
            <input type="file" name="anexo_2" id="anexo_2"
              accept="image/*, .pdf, .docx, .doc, .xlsx, .ods, .zip, .ppt, .pptx, .txt"
            >
            <input type="file" name="anexo_3" id="anexo_3"
              accept="image/*, .pdf, .docx, .doc, .xlsx, .ods, .zip, .ppt, .pptx, .txt"
            >
            <small class="help">• Anexos podem ser do pacote Office, PDFs e imagens.</small>
            <br>
            <small class="help">• Cada um pode ter no máximo 5 MB.</small>
          </div>

					<div class="input-group actions">
						<button type="button" @click="toggleModal" class="button cancel clear_button">Cancelar</button>
						<button class="button new">Adicionar</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import EventService from '@/services/EventService'
import handleError from '@/mixins/handleError'

import IconeFechar from '@/assets/SVGs/iconeFechar.vue'
import selectorUsuarios from '@/components/Seletor/Usuarios.vue'

export default {
  components: {
    'icone-fechar': IconeFechar,
    'seletor-usuarios': selectorUsuarios,
  },
  data() {
    return {
      dataHoje: new Date().toISOString().slice(0, 16),
      modalData: {
        titulo: '',
        descricao: '',
        prazo: null,
        responsaveis: [],
      },
      senhas_diferentes: false,
    }
  },
  mixins: [handleError],
  computed: {
    dataHoje() {
      const { verifyDD } = require('../../..//utils/dates')
      const data = new Date()
      return `${data.getFullYear()}-${verifyDD(data.getMonth()+1)}-${verifyDD(data.getDate())}`
    },

    dataProximoAno() {
      const { verifyDD } = require('../../..//utils/dates')
      const data = new Date()
      return `${data.getFullYear()+1}-${verifyDD(data.getMonth()+1)}-${verifyDD(data.getDate())}`
    }
  },
  methods: {
    toggleModal() {
      this.$emit('toggleAddDemanda', { target: 'addDemanda' })
    },
    dblClickHandler(e) {
      if(e.target.className === 'modal-overlay active')
        this.toggleModal()
    },
		selectResponsaveis({ usuariosSelecionados }) {
			this.modalData.responsaveis = usuariosSelecionados
		},

    validate() {
      if(this.modalData.responsaveis.length == 0) return this.$Swal.fire('Selecione pelo menos 1 responsável!')
      //Anexos
      let arq_1 = document.getElementById('anexo_1').files[0]
      let arq_2 = document.getElementById('anexo_2').files[0]
      let arq_3 = document.getElementById('anexo_3').files[0]
      let flagIguais = false

      if(arq_1?.size > 5*1024*1024)
        return this.$Swal.fire('O anexo 1 é maior do que 5 MB, coloque ele no Drive e mencione o link na descrição, ou diminuia o tamanho do arquivo.')
      if(arq_2?.size > 5*1024*1024)
        return this.$Swal.fire('O anexo 2 é maior do que 5 MB, coloque ele no Drive e mencione o link na descrição, ou diminuia o tamanho do arquivo.')
      if(arq_3?.size > 5*1024*1024)
        return this.$Swal.fire('O anexo 3 é maior do que 5 MB, coloque ele no Drive e mencione o link na descrição, ou diminuia o tamanho do arquivo.')

      if(arq_1?.size == arq_2?.size && arq_1?.size == arq_3?.size && arq_1 != undefined){
        arq_2 = undefined
        arq_3 = undefined
        flagIguais = true
      }
      else if(arq_1?.size == arq_2?.size && arq_1 != undefined){
        arq_2 = undefined
        flagIguais = true
      }
      else if(arq_1?.size == arq_3?.size && arq_1 != undefined){
        arq_3 = undefined
        flagIguais = true
      }
      else if(arq_2?.size == arq_3?.size && arq_2 != undefined){
        arq_3 = undefined
        flagIguais = true
      }

      if(flagIguais) {
        this.$Swal.fire({
          title: 'Anexos iguais',
          text: 'Alguns dos anexos colocados são iguais, o sistema removerá os duplicados. Deseja continuar com o envio?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Cancelar'
        }).then(result => {
          if (!result.isConfirmed) return
          this.submit(arq_1, arq_2, arq_3)
        })
        return
      }
      this.submit(arq_1, arq_2, arq_3)
    },

    async submit(arq1, arq2, arq3) {
      let dataForm = new FormData();

      if(arq1) dataForm.append(`arq1`, arq1);
      if(arq2) dataForm.append(`arq2`, arq2);
      if(arq3) dataForm.append(`arq3`, arq3);
      
      dataForm.append(`titulo`, this.modalData.titulo);
      dataForm.append(`descricao`, this.modalData.descricao);
      dataForm.append(`prazo`, this.modalData.prazo);
      dataForm.append(`responsaveis`, this.modalData.responsaveis);

      this.$store.dispatch('loading')

      EventService.createDemanda(dataForm)
      .then(({ demanda_criada }) => {
        this.clearOnCreate()
        this.$emit('createdDemanda', { demanda_criada })
      })
      .catch(this.handleError)
    },

    clearOnCreate() {
      this.$store.dispatch('notLoading')

      this.modalData = {
        titulo: '',
        descricao: '',
        prazo: null,
        responsaveis: '',
      }

      this.toggleModal()
      
      this.$Swal.fire({
          title: "Demanda aberta!",
          icon: "success"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../Modals.scss';
@import 'ModalDemandas.scss';
</style>