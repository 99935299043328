<template>
  <div class="header_pagina">
    <h1>Demandas</h1>
		<my-button icone="add" texto="Abrir demanda" @buttonClick="toggleModalLocal('addDemanda')" />
		<transition name="fade">
			<modal-add v-if="modalStates.addDemanda" @toggleAddDemanda="toggleModal" @createdDemanda="addDemanda"/>
		</transition>
	</div>

  <section id='section_filtro'>
		<div id="header_filtro">
				<h2 id='titulo_section_filtro'>Filtros de pesquisa</h2>
		</div>
		<div id="area_filtros">
			<div class="area_parametro_filtro">
				<label for="filtro_titulo">Titulo</label>
				<input class='filtro' name="filtro_titulo" id="filtro_titulo" placeholder="Titulo"
			 		v-model="filter.titulo"
				/>
			</div>

			<div class="area_parametro_filtro">
				<label for="filtro_status">Status</label>
				<select class='filtro' name="filtro_status" id="filtro_status"
			 		v-model="filter.status"
				>
					<option value="" selected>- Sem restrição -</option>
					<option value="Abertura">Abertura</option>
					<option value="Em andamento">Em andamento</option>
					<option value="Concluída">Concluída</option>
				</select>
			</div>

			<div class="area_parametro_filtro">
				<label for="filtro_status">Prazo</label>
				<select class='filtro' name="filtro_status" id="filtro_status"
			 		v-model="filter.prazo"
				>
					<option value="" selected>- Sem restrição -</option>
					<option value="Mais de um dia">Mais de um dia</option>
					<option value="Amanhã">Amanhã</option>
					<option value="Passados">Passados</option>
					<option value="data">Data</option>
				</select>

        <div id="dates" v-show="filter.prazo == 'data'">
          <table>
            <tbody>
              <tr>
                <th>Início:</th>
                <td><input type="date" id="filtro_prazo_inicial" v-model="filter.prazo_datas.inicial"></td>
              </tr>
              <tr>
                <th>Fim:</th>
                <td><input type="date" id="filtro_prazo_final" v-model="filter.prazo_datas.final"></td>
              </tr>
            </tbody>
          </table>
        </div>
			</div>
		</div>
	</section>

  <section style="margin: 0 1rem;">
    <p v-if="!loaded">Carregando...</p>
    <div v-else>
      <div>
        <h2>Responsável</h2>
        <p v-if="demandasResponsavel.length == 0">Nenhuma demanda para você!</p>
        
        <table-responsavel v-else
          :demandas="demandasResponsavel"
          :filter="filter"
          :remover_check="true"  
        />
      </div>

      <div id="secoes_demandas" >
        <div>
          <h2>Autor</h2>

          <section id="acoes"
            v-if="demandasSelecionadas.length > 0"
          >
            <div id="regiao_acoes">
              <my-button icone="delete" texto="Deletar" 
                style="margin-right: 1rem;"
                @buttonClick="toggleDeleteDemandas()"
              />
            </div>
          </section>

          <p v-if="demandasAutor.length == 0">Nenhuma demanda por você!</p>
          
          <table-autor v-else
            :demandas="demandasAutor"
            :filter="filter"
            @checkmarkChange="selectDemandas"
          />
          
        </div>

        <div>
          <h2>Setor</h2>
          <p v-if="demandasSetor.length == 0">Nenhuma demanda no seu setor!</p>
          <table-setor v-else 
            :demandas="demandasSetor"
            :filter="filter"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import socket from "@/services/socket";

import EventService from "@/services/EventService";

import handleError from '@/mixins/handleError'
import toggleModals from '@/mixins/toggleModals'

import ModalAdd from '@/components/Modals/Demandas/Add.vue'
import ModalEdit from '@/components/Modals/Demandas/Edit.vue'

import TabelaResponsavel from '@/components/Tables/Demandas/Responsavel.vue'
import TabelaAutor from '@/components/Tables/Demandas/Autor.vue'
import TabelaSetor from '@/components/Tables/Demandas/Setor.vue'

import Button from '@/components/Buttons/Button.vue'

export default {
  components: {
    'modal-add': ModalAdd,
    'modal-edit': ModalEdit,
    'my-button': Button,
    'table-responsavel': TabelaResponsavel,
    'table-autor': TabelaAutor,
    'table-setor': TabelaSetor,
  },
  data() {
    return {
      socketInstance: {},

			loaded: false,

      demandas: [],

      filter: {
        titulo: '',
        status: '',
        prazo: '',
        prazo_datas: {}
      },

			demandaEdit: {},
			demandasSelecionadas: [],

			modalStates: {
				adddemanda: false,
				editDemanda: false,
			},
    };
  },
  mixins: [handleError, toggleModals],
  async mounted() {
    try {
      const response = await EventService.fetchDemandasUser()
      this.demandas = response.demandas;
      this.loaded = true
      
      this.socketInstance = socket
      this.socketInstance.on(
        "demanda:postada", (data) => {
          if(data.socket_id == this.socketInstance.id) return
          delete data.socket_id

          const responsaveis_local = data.responsaveis.map(({_id}) => _id)
          const responsavel = responsaveis_local.includes(this.$store.state.user.id)

          if(!responsavel) return
          this.demandas.push(data);
        }
      )
    } catch(e) {
      this.loaded = true
      console.log(e)
      handleError(e)
    }
  },
  methods: {
    addDemanda({ demanda_criada }) {
      this.demandas.push(demanda_criada)
      this.socketInstance.emit('demanda:iniciada', demanda_criada);
    },
		selectDemandas(checkmark) {
      if(checkmark.state) this.demandasSelecionadas.push(checkmark.id)
      else
        this.demandasSelecionadas.splice(this.demandasSelecionadas.indexOf(checkmark.id), 1)

      if(this.demandasSelecionadas.length != 1) this.demandaEdit = {}
      else{
        const id_selecionada = this.demandasSelecionadas[0]
        this.demandas.forEach(demanda => {
          if(demanda._id == id_selecionada) this.demandaEdit = demanda
        })
      }
		},
    toggleDeleteDemandas(){
			let title = `Deletar demanda`
			let text = `Tem certeza que quer deletar a demanda '${this.demandaEdit?.titulo}'?`

			if(this.demandasSelecionadas.length > 1) {
				title += `s`
				text = `Tem certeza que quer deletar ${this.demandasSelecionadas.length} demandas?`
			}

      this.$Swal.fire({
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (!result.isConfirmed) return
        try {
					const ids = [...this.demandasSelecionadas]
					EventService.deleteDemandas({ ids })
					.then(() => {
            this.clearDemandas(ids)
						this.$store.dispatch('notLoading')
						this.$Swal.fire({
								title: "Operação de deleção concluída!",
								icon: "success"
						})
            this.demandasSelecionadas = []
            this.demandaEdit = {}
					})
					.catch(this.handleError)
        } catch(e) {
          this.handleError(e)
          this.demandasSelecionadas = []
          this.demandaEdit = {}
        }
      })
    },
    clearDemandas(ids) {
			this.demandas = this.demandas.filter(({_id}) => !ids.includes(_id) )
			this.demandaEdit = {}
    },
  },
  computed: {
    demandasSetor() {
      return this.demandas.filter((demanda) => {
        const not_autor = demanda.autor._id != this.$store.state.user.id
        const setor = demanda.setores.includes(this.$store.state.user.setor)

        const ids_responsaves = demanda.responsaveis.map(({_id}) => _id)
        const nao_responsavel = !ids_responsaves.includes(this.$store.state.user.id)

        return not_autor && setor && nao_responsavel
      })
    },
    demandasAutor() {
      return this.demandas.filter(({ autor }) => autor._id == this.$store.state.user.id)
    },
    demandasResponsavel() {
      return this.demandas.filter(({ responsaveis }) => {
        const responsaveis_local = responsaveis.map(({_id}) => _id)
        return responsaveis_local.includes(this.$store.state.user.id)
      } )
    },
  }
}
</script>

<style lang="scss" scoped>
@import 'Demandas.scss';
</style>